import {Injectable} from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {environment} from '@env/environment';
import {Observable, take} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HandshakeParams} from '../models/handshakeParams';
import {HandshakeService} from '../services/handshake.service';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class HandshakeGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private router: Router,
                private route: ActivatedRoute,
                private handshakeService: HandshakeService,
                private snackbar: MatSnackBar) {
        console.log('HandshakeGuard --> constructor');
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log("route in canActivate: ",route);
        return this.isShortenUrlValid(route);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log("childRoute in canActivate: ",childRoute);
        return this.isShortenUrlValid(childRoute);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // return this.isShortenUrlValid(this.route);
        return true;
    }

    isShortenUrlValid(route: ActivatedRouteSnapshot) {
        const shortenUrlParam: string = route?.params?.shortenUrl as string;
        const tokenParam: string = route?.params?.token as string;
        console.log('shortenUrl --> ', shortenUrlParam);
        console.log('specific route :',route);
        console.log('token --> ',tokenParam);
        console.log('params -->',route.params as string[]);

        try {
            if (!!shortenUrlParam) {
                const handshakeParams: HandshakeParams = {
                    shortenUrl: shortenUrlParam,
                    pool: environment.pool,
                    customer: environment.customer,
                    token: tokenParam
                };
                // fetching
                console.info('fetching shortenUrl: ', shortenUrlParam);

                return this.handshakeService.checkShortenUrl(handshakeParams)
                    .pipe(
                        map((res) => {
                            this.handshakeService.selected = res;

                            if (this.handshakeService?.selected || !this.handshakeService?.selected?.features)
                                return true;



                            console.log('handshake success --> res --> ', res);
                            if (!res.features.msa && !res?.features?.ticketing){
                               this.router.navigate(['fehler/404'])
                                return false;
                            }

                            else
                                return true;
                        }),
                        take(1),
                        catchError((err) => {
                            // Getting the Error details.
                            const code = err.code;
                            const message = err.message;
                            const details = err.details;
                            console.log('Error: ', err);
                            console.log('err.code: ', err.code);
                            console.log('err.message: ', err.message);
                            console.log('err.details: ', err.details);


                            switch (code) {
                                case 'functions/failed-precondition':
                                    console.log("entered jehere")
                                    return this.router.navigate(['fehler', '404']);
                                case 'functions/not-found':
                                    this.snackbar.open(err.message, 'OK', {duration: 10000});
                                    return this.router.navigate(['fehler', '404']);
                                case 'functions/unknown':
                                    return this.router.navigate(['fehler', '500']);
                            }

                            return this.router.navigate(['fehler', '500']);
                            // return of(false);
                        })
                    );

            } else {
                return this.router.navigate(['fehler', '500']);
                // return this.router.navigate(['mieterselbstauskunft', 'code-eingeben']);
            }
        } catch (e) {
            console.error('Error in isShortenUrlValid --> ', e);
            return this.router.navigate(['fehler', '500']);
        }

    }
}
